<template>
  <header class="heading-home">
    <div v-if="currentDestination" class="image-wrapper">
      <SocialLinks
        v-if="socials"
        class="social-links"
        :socials="socials"
        main-color="white"
        height="three-quarter"
        anchor-id="description"
      />
      <div class="gradient" />
      <TwicPicture
        class="image"
        :src="
          currentDestination.attributes.image?.data?.attributes?.url ??
          currentDestination.attributes.parent?.data.attributes.image?.data?.attributes.url
        "
        :alt="title"
        focus="auto"
        ratio="4:3, @sm 16:9, @xl 2:1, @2xl 4:1"
        sizes="100vw"
        eager
      />
      <ShadowedText class="title" :text="h1Title" />
      <div class="search-container">
        <InputsSearchBar
          v-model="searchString"
          :placeholder="placeholder"
          :options="[]"
          :is-loading="isSearchLoading"
          :button-text="primaryButton"
          :trend-countries="trendCountries"
        />
      </div>
      <div class="loop-wrapper">
        <SliderSwiperArrows v-model="swiper" class="arrow-left" which="left" is-loop />
        <SliderSwiperWrapper
          v-model="swiper"
          class="destinations"
          loop
          :active-index="newActiveIndex"
          :breakpoints="{
            [BREAKPOINTS.mobileSm]: {
              slidesPerView: 1
            },
            [BREAKPOINTS.mobile]: {
              slidesPerView: 3
            },
            [BREAKPOINTS.desktop]: {
              slidesPerView: 3
            },
            [BREAKPOINTS.desktopLg]: {
              slidesPerView: 5
            }
          }"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }"
          :loop-prevents-sliding="false"
          :autoplay="{ delay: 1000 }"
          slide-to-clicked-slide
          centered-slides
        >
          <SwiperSlide
            v-for="destination of destinations"
            :key="destination.id"
            v-slot="{ isActive }"
          >
            <div class="destination-title" :class="{ selected: isActive }">
              {{ destination.attributes.title }}
            </div>
          </SwiperSlide>
        </SliderSwiperWrapper>
        <SliderSwiperArrows v-model="swiper" class="arrow-right" which="right" is-loop />
        <div class="current-products">
          <MosaicCard
            v-for="currentJourney in currentJourneys"
            :key="currentJourney.id"
            :height="300"
            :width="335"
            priority
            :image="
              currentJourney.attributes.imageSlugs?.[0] ?? '/uploads/placeholder_5109e12df7.svg'
            "
            :link="getJourneySlug({ destination: currentDestination, journey: currentJourney })"
          >
            <MosaicFavorite :journey="currentJourney" />
          </MosaicCard>
          <MosaicCard
            :image="currentDestination.attributes.image?.data?.attributes?.url ?? ''"
            :height="300"
            :width="236"
            priority
            :link="`/${currentDestination.attributes.urlPath}`"
          >
            <div class="main-category-title">
              Voir tous les voyages {{ currentDestination?.attributes.mdvDstArticle }}
              {{ currentDestination?.attributes.title }}
            </div>
          </MosaicCard>
        </div>
        <div class="current-products-swiper">
          <SliderSwiperWrapper
            v-model="swiperCountries"
            :slides-per-view="1.3"
            centered-slides
            :space-between="16"
          >
            <SwiperSlide v-for="(currentJourney, index) in currentJourneys" :key="index">
              <MosaicCard
                :key="currentJourney.id"
                class="mobile-destination"
                :height="220"
                fluid
                priority
                :image="
                  currentJourney.attributes.imageSlugs?.[0] ?? '/uploads/placeholder_5109e12df7.svg'
                "
                :link="getJourneySlug({ destination: currentDestination, journey: currentJourney })"
              >
                <MosaicFavorite :journey="currentJourney" width="285" />
              </MosaicCard>
            </SwiperSlide>
            <SwiperSlide>
              <MosaicCard
                class="mobile-destination more"
                :image="currentDestination.attributes.image?.data?.attributes?.url ?? ''"
                :height="220"
                fluid
                priority
                :link="`/${currentDestination.attributes.urlPath}`"
              >
                <div class="main-category-title">
                  Voir tous les voyages {{ currentDestination?.attributes.mdvDstArticle }}
                  {{ currentDestination?.attributes.title }}
                </div>
              </MosaicCard>
            </SwiperSlide>
          </SliderSwiperWrapper>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { BREAKPOINTS } from '@/lib/variables';

import { Category } from '@/lib/strapi-types/Category';
import { Social } from '@/lib/strapi-types/components/Social';
import { Country } from '@/lib/strapi-types/Country';

import { SwiperBinding } from '@/lib/types/models/swiper';

const { destinations, title } = defineProps<{
  title?: string;
  placeholder?: string;
  primaryButton?: string;
  secondaryButton?: string;
  destinations: Category[];
  socials?: Social[];
  trendCountries?: Country[];
}>();

const searchString = ref('');
const isSearchLoading = ref(false);
const swiper = ref<SwiperBinding>();
const swiperCountries = ref<SwiperBinding>();

const newActiveIndex = computed(() => {
  if (swiper.value?.activeIndex) {
    return swiper.value.activeIndex - 2;
  }
});

const currentDestination = computed(() => destinations[swiper.value?.realIndex ?? 0]);

// TODO: if TODO in useHomePageHero is resolved, we should be able to remove this slice(0, 3)
const currentJourneys = computed(() =>
  currentDestination.value?.attributes.journeys?.data.slice(0, 3)
);

const h1Title = `<h1 class="title">${title}</h1>`;
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/button.scss';
@use '$/spacings.scss';
@use '$/breakpoints.scss';
@use '$/typography.scss';
@use '$/misc.scss';

.heading-home {
  flex-direction: column;
  width: 100%;
  height: 1000px;

  .image-wrapper {
    position: relative;

    flex-direction: column;
    gap: 40px;
    align-items: center;

    width: 100%;
    height: 770px;
    padding: 0 64px;

    border-bottom: solid 5px colors.$gold;

    .loop-wrapper {
      position: absolute;
      bottom: -240px;

      flex-direction: column;

      width: 100vw;
      padding: 0 60px;

      .current-products {
        position: relative;

        gap: 24px;
        align-items: center;
        justify-content: center;

        width: 100%;
        margin-top: 40px;
        padding: 0 spacings.$desktop-horizontal-lg;

        :deep(.card-container:last-child) {
          background: rgb(0 0 0 / 100%);
        }

        .main-category-title {
          position: absolute;
          z-index: 5;

          align-items: center;
          justify-content: center;

          height: 100%;
          padding: spacings.$mobile-md;

          font-size: 24px;
          font-weight: 600;
          color: white;
          text-align: center;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .current-products-swiper {
        display: none;
      }

      :deep(.swiper-wrapper) {
        width: 100vw;
      }

      .arrow-right {
        position: absolute;
        z-index: 5;
        top: 12px;
        right: 64px;

        &:deep(.icon) {
          width: 40px;
          height: 40px;

          svg {
            width: 40px;
            height: 40px;

            path {
              stroke: white !important;
            }
          }
        }
      }

      .arrow-left {
        position: absolute;
        z-index: 5;
        top: 12px;
        left: 64px;

        &:deep(.icon) {
          width: 40px;
          height: 40px;

          svg {
            width: 40px;
            height: 40px;

            path {
              stroke: white !important;
            }
          }
        }
      }

      .destinations {
        :deep(.swiper) {
          width: auto;
        }

        :deep(.swiper-slide) {
          align-items: center;
          justify-content: center;
        }

        .destination-title {
          cursor: pointer;

          width: fit-content;

          font-size: 40px;
          font-weight: 600;
          color: white;
          text-align: center;

          opacity: 0.6;

          &.selected {
            opacity: 1;
          }
        }
      }
    }

    .social-links {
      position: absolute;
      left: 0;
      padding-left: 44px;
    }

    .search-container {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;

      width: 100%;
      max-width: 1040px;
    }

    .title {
      z-index: 1;

      max-width: 647px;
      margin-top: 160px;

      font-size: 56px;
      text-align: center;
    }

    .gradient {
      @include misc.gradient();

      z-index: 0;
      background: rgb(0 0 0 / 30%);
    }

    .image {
      position: absolute;
      z-index: -1;

      @keyframes gray-to-color {
        from {
          filter: grayscale(1);
        }

        to {
          filter: grayscale(0);
        }
      }

      width: 100%;
      height: 100%;

      animation: gray-to-color 7s linear;
    }
  }
}

@include breakpoints.tablet() {
  .heading-home {
    .image-wrapper {
      padding: 0 spacings.$mobile-md;

      .social-links {
        display: none;
      }

      .search-container {
        flex-direction: column;
      }
    }
  }
}

@include breakpoints.mobile() {
  .heading-home {
    height: 647px;

    .image-wrapper {
      height: 442px;

      .title {
        margin-top: 85px;
        font-size: typography.$mobile-title;
      }

      .search-container {
        gap: 8px;
      }

      .loop-wrapper {
        bottom: -180px;

        .arrow-right {
          top: 4px;
          right: 16px;

          &:deep(.icon) {
            width: 24px;
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .arrow-left {
          top: 4px;
          left: 16px;

          &:deep(.icon) {
            width: 24px;
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .current-products {
          display: none;
        }

        .current-products-swiper {
          display: flex;
          margin-top: 24px;

          .main-category-title {
            position: relative;
            z-index: 5;

            align-items: center;
            justify-content: center;

            height: 100%;
            padding: spacings.$mobile-md;

            font-size: 24px;
            font-weight: 600;
            color: white;
            text-align: center;
          }

          .mobile-destination {
            &.more {
              display: flex;
              align-items: center;
              justify-content: center;

              .main-category-title {
                height: unset;

                :hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .destinations {
          padding: 0 24px;

          .destination-title {
            font-size: typography.$mobile-sub-title;
          }
        }
      }
    }
  }
}
</style>
