<template>
  <div id="home">
    <HeadingHome
      v-if="destinations"
      class="header"
      :title="heroTitle"
      :placeholder="heroPlaceholder"
      :primary-button="heroPrimaryButton"
      :secondary-button="heroSecondaryButton"
      :destinations="destinations"
      :socials="socials"
      :trend-countries="trendsSection?.countries.data"
    />

    <LazySectionInspirations
      v-if="inspirationSection"
      class="inspirations"
      :title="inspirationSection.title"
      :link="inspirationSection.link"
      :image-and-texts="inspirationSection.imageAndTexts"
    />

    <LazySectionPopularDestinations
      v-if="trendsSection && trendDestinations"
      :title="replaceHighlightHtml(trendsSection.title)"
      :description="trendsSection.description"
      :destinations="trendDestinations"
    />

    <LazySectionMapFilters
      :data="{
        destinations: [],
        seasons: periodsFilters ?? [],
        typeCategories: travelStyleFilters ?? []
      }"
      :other-destinations="otherDestinationsMapSection?.countries.data ?? []"
      :selected-filter="filtersObject"
      :countries-by-journeys="countriesByJourneys"
      class="map-filters"
      @set-filters="
        (e: FiltersObject) => {
          handleSetFilters(e);
          pagination = defaultPagination;
        }
      "
      @on-destination-click="setNewDestination"
      @toggle-modal="handleToggleModal"
      @reset-filters="handleResetFilters"
    />
    <LazySectionGridResults
      v-if="filters.length > 0"
      class="journeys"
      result-type="journey"
      :results="resultsProductModel ?? []"
      :is-loading="isLoadingResults"
      :is-loading-pagination="isLoadingPagination"
      :filters-prop="filters ?? []"
      :pagination="pagination"
      :total-count="resultsResponse?.meta.pagination.total"
      @on-page-change="onPageChange"
      @toggle-modal="handleToggleModal"
      @toggle-show-mobile-map="handleToggleShowMobileMap"
      @on-delete="handleDeleteFilter"
      @on-reset="handleResetFilters"
      @on-ordering="updateSort"
    />
    <FiltersModal
      :filters-to-display="[
        MODAL_FILTERS.PERIOD,
        MODAL_FILTERS.WISH_CATEGORY,
        MODAL_FILTERS.DURATION,
        MODAL_FILTERS.BUDGET
      ]"
      :selected-filter="filtersObject"
      :fetched-filter-data="{
        seasons: periodsFilters ?? [],
        typeCategories: travelStyleFilters ?? [],
        wishCategories: []
      }"
      :is-open="isModalOpened"
      :country-name="mainResultName"
      :average-budget="{ min: 0, max: 0 }"
      @reset-filters="handleResetFilters"
      @delete-filter="handleDeleteFilter"
      @set-filters="
        (e: FiltersObject) => {
          handleSetFilters(e);
          pagination = {
            start: 0,
            limit: 8,
            withCount: true
          };
        }
      "
      @toggle-modal="handleToggleModal"
      @toggle-show-mobile-map="handleToggleShowMobileMap"
    />

    <LazySectionBannerSlider
      v-if="themesSection && themesSection.inspirations"
      class="desires"
      is-points
      :title="replaceHighlightHtml(themesSection.title)"
      :inspirations="themesSection.inspirations.data.slice(0, 10)"
    />

    <LazySectionExpertsSlider
      v-if="expertsSection"
      class="experts"
      :link="{ slug: 'nos-conseillers', name: 'Voir tous les experts' }"
      :title="expertsSection.title"
      :experts="expertsSection.experts.data"
    />

    <LazySectionEventsSlider
      v-if="eventsSection && eventsSection.events && eventsSection.events.data.length > 0"
      class="edito"
      :title="replaceHighlightHtml(eventsSection.title)"
      :events="eventsSection.events.data"
    />

    <LazySectionBanner
      v-if="commitmentSection && commitmentSection.CTA"
      class="commitment"
      :cta="commitmentSection.CTA"
      :title="commitmentSection.title"
      :description="commitmentSection.description"
      :icon-url="commitmentSection.icon?.data?.attributes.url"
      :image-url="commitmentSection.image.data.attributes.url"
    />

    <LazySectionTravelBookSlider
      v-if="articlesSection && articlesSection.articles"
      :title="replaceHighlightHtml(articlesSection.title)"
      :articles="articlesSection.articles?.data"
    />

    <LazySectionBanner
      v-if="imageAndTextSection && imageAndTextSection.CTA"
      :cta="imageAndTextSection.CTA"
      :title="imageAndTextSection.title"
      :description="imageAndTextSection.description"
      :icon-url="imageAndTextSection.icon?.data?.attributes.url"
      :image-url="imageAndTextSection.image.data.attributes.url"
    />

    <LazySectionBannerPlaceholder
      v-if="marketingPushSection"
      class="marketing-push"
      :image="marketingPushSection.image.data.attributes.url"
    >
      <MarketingPush
        :geographic-zone-slug="marketingPushSection.geoZone?.data?.attributes.slug"
        :logo="marketingPushSection.logo.data.attributes.url"
        :title="marketingPushSection.title"
        :subtitle="marketingPushSection.subtitle"
        :cta="marketingPushSection.CTA"
      />
    </LazySectionBannerPlaceholder>

    <LazySectionGroups
      v-if="groupsSection"
      class="groups"
      :title="replaceHighlightHtml(groupsSection.title)"
      :subtitle="groupsSection.subtitle"
      :cards="groupsSection.cards"
      :cta="groupsSection.CTA"
    />

    <LazySectionRSEBanner v-if="responsabilities" :responsabilities="responsabilities" />

    <LazySectionRatings
      v-if="ratingsSection"
      class="ratings"
      :title="replaceHighlightHtml(ratingsSection.title)"
      :average="4"
      :count="homeMockRatings ? homeMockRatings?.ratings.length - 1 : 0"
      :ratings="homeMockRatings?.ratings ?? []"
    />
  </div>
</template>

<script lang="ts" setup>
import { defaultPagination } from '@/lib/strapiFilters';

import { homeMockRatings } from '@/lib/mock/home';

import getRobotsValue from '@/utils/getRobotsValue';

import MODAL_FILTERS from '@/lib/types/enums/modalFilters.enum';
import { FiltersObject } from '@/lib/types/filters';

const [
  {
    heroTitle,
    heroPlaceholder,
    heroPrimaryButton,
    heroSecondaryButton,
    heroCountryIsoCodes,
    themesSection,
    inspirationSection,
    marketingPushSection,
    groupsSection,
    imageAndTextSection,
    otherDestinationsMapSection,
    meta
  },
  {
    socials,
    responsabilities,
    commitmentSection,
    ratingsSection,
    expertsSection,
    articlesSection,
    eventsSection,
    trendsSection
  }
] = await Promise.all([
  useHomePage({
    filters: {},
    populate: {
      hero: {
        fields: ['title', 'placeholder', 'primaryButton', 'secondaryButton', 'countries'],
        populate: {
          countries: {
            fields: ['iso']
          }
        }
      }
    }
  }),
  useCommon()
]);

const [{ categories: unsortedDestinations }, { categories: trendDestinations }] = await Promise.all(
  [
    useFindCategories(
      {
        fields: ['title', 'urlKey', 'urlPath', 'mdvDstMapsShortname'],
        filters: {
          categoryMdvAttribute: 'country',
          mdvDstMapsShortname: {
            $in: heroCountryIsoCodes
          }
        },
        populate: {
          fields: ['title', 'journeys'],
          image: {
            fields: ['url']
          },
          journeys: {
            sort: ['position:asc'],
            fields: [
              'title',
              'images',
              'generalTravelType',
              'days',
              'nights',
              'price',
              'slug',
              'imageSlugs',
              'watabiContent'
            ],
            populate: {
              watabiContent: {
                fields: ['location'],
                populate: {
                  location: { fields: ['name'] }
                }
              }
            }
          },
          parent: {
            fields: ['title', 'urlKey'],
            populate: {
              image: {
                fields: ['url']
              }
            }
          }
        }
      },
      { limitJourneys: true }
    ),
    useFindCountryCategories(trendsSection?.countries.data ?? [])
  ]
);

const destinations = unsortedDestinations?.sort((a: any, b: any) => {
  return (
    (heroCountryIsoCodes ?? []).indexOf(a.attributes.mdvDstMapsShortname) -
    (heroCountryIsoCodes ?? []).indexOf(b.attributes.mdvDstMapsShortname)
  );
});

const {
  travelStyleFilters,
  periodsFilters,
  onPageChange,
  isModalOpened,
  handleToggleModal,
  handleSetFilters,
  handleResetFilters,
  handleDeleteFilter,
  handleToggleShowMobileMap,
  filters,
  filtersObject,
  mainResultName,
  countriesByJourneys,
  resultsResponse,
  isLoadingResults,
  isLoadingPagination,
  resultsProductModel,
  updateSort,
  setNewDestination,
  pagination
} = await useSectionMapFilters();

const route = useRoute();

useHead({
  title: meta?.title,
  meta: [
    {
      hid: 'og:title',
      property: 'og:title',
      content: meta?.title
    },
    {
      hid: 'description',
      name: 'description',
      content: meta?.description
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: meta?.description
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: route.fullPath
    },
    {
      // add an global website image
      hid: 'og:image',
      property: 'og:image',
      content: ''
    },
    {
      hid: 'robots',
      name: 'robots',
      content: getRobotsValue(meta?.noIndex, meta?.noFollow)
    }
  ]
});
</script>

<style lang="scss" scoped>
@use '$/spacings.scss';
@use '$/breakpoints.scss';
@use '$/typography.scss';
@use '$/button.scss';
@use '$/misc.scss';

#home {
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .header {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  :deep(.popular-destinations) {
    margin-bottom: spacings.$desktop-vertical-lg;
  }

  :deep(.popular-countries) {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  .inspirations {
    margin-bottom: calc(spacings.$desktop-vertical-md);
  }

  &:deep(.popular-destinations) {
    margin-bottom: spacings.$desktop-vertical-lg;
  }

  .map-filters {
    margin-bottom: spacings.$desktop-vertical-lg;
  }

  .journeys {
    margin-bottom: 30px;
  }

  .desires {
    margin-bottom: spacings.$desktop-vertical-lg;
  }

  .experts {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  :deep(.event-slider) {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  .edito {
    :deep(.content) {
      .swiper-wrapper {
        padding-bottom: spacings.$desktop-vertical-lg;
      }
    }
  }

  :deep(.rse-banner) {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  .commitment {
    :deep(.element) {
      align-items: center;
      justify-content: center;
      text-align: center;

      .banner-content {
        gap: 16px;
        justify-content: center;
        width: 780px;
        text-align: justify;
      }
    }
  }

  .ratings {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  .marketing-push {
    margin-bottom: spacings.$desktop-vertical-md;
  }

  .groups {
    margin-bottom: spacings.$desktop-vertical-md;
  }
}

@include breakpoints.tablet() {
  #home {
    .header {
      margin-bottom: 24px;
    }

    :deep(.popular-destinations-mobile) {
      padding-top: spacings.$mobile-xl;
      padding-bottom: spacings.$mobile-xl;
    }

    .inspirations {
      margin-bottom: 0;
    }

    &:deep(.popular-destinations-mobile) {
      margin-bottom: spacings.$mobile-xl;
    }

    .map-filters {
      margin-bottom: spacings.$mobile-xl;
    }

    .desires {
      margin-bottom: spacings.$mobile-xl;
    }

    .edito {
      :deep(.content) {
        .swiper-wrapper {
          padding-bottom: spacings.$mobile-xl;
        }
      }
    }

    .experts {
      margin-bottom: spacings.$mobile-horizontal;
    }

    :deep(.rse-banner-mobile) {
      margin-bottom: spacings.$mobile-xl;
    }

    .commitment {
      :deep(.banner-content) {
        gap: 16px;
        padding: 16px;
      }

      :deep(.item-content) {
        align-items: center;
        width: 100%;

        .item-title {
          font-size: typography.$mobile-h2;
        }

        .item-sub-title {
          font-size: typography.$mobile-body-l;
        }
      }
    }

    .ratings {
      margin-bottom: spacings.$mobile-xl;
    }

    .marketing-push {
      margin-bottom: spacings.$mobile-xl;
    }

    .groups {
      margin-bottom: spacings.$mobile-xl;
    }
  }
}

@include breakpoints.mobile() {
  #home {
    :deep(.event-slider) {
      margin-bottom: 0;
    }
  }
}
</style>
